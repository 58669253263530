<template>
  <div class="main-container">
    <navbar v-if="currentUser"/>
    <div class="body">
     <transition name="fade" mode="out-in">
        <router-view class="router-view" v-if="currentUser"/>
        <div class="loader" v-else><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
     </transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Navbar from '@/components/common/Navbar'

export default {
  computed: mapGetters(['currentUser']),
  components: {
    Navbar
  },
  mounted () {
    if (Vue.prototype.$cookie.get('accessToken')) {
      this.$store.dispatch('getCurrentUser')
    }
  }
}
</script>

<style lang="scss">
@import "./Authenticated";
</style>
