<template>
  <div class="Navbar">
    <div class="Navbar__logo">
      <img class="Navbar__avatar-image"
        src="@/assets/img/kc-logo.svg"/>
      <div class="Navbar__logo-text">
        <b>KingsChat</b> for developers
      </div>
    </div>
    <div class="Navbar__navigation">
      <div class="pointer">
        <a href="mailto:developer@joinkingschat.com">Contact Support</a>
      </div>
      <div class="pointer" @click="goTo({ name: 'docs' })">
        <a>Docs</a>
      </div>
      <div class="pointer" @click="goTo({ name: 'projects' })">
        <a>My Projects</a>
      </div>
      <img v-if="currentUser.avatarUrl" class="Navbar__avatar-image"
        :src="currentUser.avatarUrl"/>
      <img v-else class="Navbar__avatar-image"
        src="@/assets/img/default-user-avatar.jpg"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'navbar',
  methods: {
    goTo ({ name }) {
      this.$router.push({ name })
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>

<style lang="scss">
@import "./Navbar.scss";
</style>
